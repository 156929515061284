<template>
	<div>
		<div v-if="isLoading">
			<Loading />
		</div>
		<div v-else>
			<v-container fluid>
				<v-row
					justify="space-around"
					transition="slide-y-transition"
					v-if="!isSearch"
				>
					<v-col cols="2">
						<v-btn
							elevation="0"
							depressed
							color="#6200ea"
							icon
							@click.prevent="realodServices"
						>
							<v-icon>mdi-reload</v-icon>
						</v-btn>
					</v-col>
					<v-col cols="8">
						<v-dialog ref="dialog" v-model="modal" persistent>
							<template #activator="{on}">
								<div v-on="on" class="mb-2 text-center">
									{{ textCalendar }}
									{{ dateCalendar | moment("D MMM YYYY") }}
									<v-icon v-text="'mdi-calendar'" />
								</div>
							</template>
							<v-date-picker
								v-model="dateCalendar"
								scrollable
								:locale="lang"
								color="deep-purple accent-4"
								flat
							>
								<v-spacer></v-spacer>
								<v-btn
									text
									color="primary"
									@click="modal = false"
								>
									{{ $t("global.dialog.btnCancel") }}
								</v-btn>
								<v-btn
									text
									color="primary"
									@click="refreshServices"
								>
									{{ $t("global.dialog.btnToAccept") }}
								</v-btn>
							</v-date-picker>
						</v-dialog>
						<v-divider class="mx-4"></v-divider>
					</v-col>
					<v-col cols="2">
						<v-btn
							elevation="0"
							depressed
							color="#6200ea"
							icon
							@click.prevent="searchActive"
						>
							<v-icon>mdi-magnify</v-icon>
						</v-btn>
					</v-col>
				</v-row>
				<v-row
					justify="space-around"
					class="m-base"
					transition="slide-y-transition"
					v-else
				>
					<v-col cols="3">
						<v-btn
							color="#E5E3FF"
							tile
							@click.prevent="backCommand"
							elevation="0"
						>
							<v-icon>mdi-arrow-left</v-icon>
						</v-btn>
					</v-col>

					<v-col cols="9">
						<v-text-field
							v-model="idSearch"
							outlined
							clearable
							:label="
								$t('global.components.listServices.txtSearch')
							"
							dense
							class="mb-0"
							@click:clear="clearSearch"
						></v-text-field>
					</v-col>
				</v-row>
			</v-container>

			<v-list two-line v-if="items.length > 0" class="pt-0">
				<template v-for="(item, index) in items">
					<v-list-item
						class="px-2"
						:key="item.serviceNumber"
						:ripple="{ class: 'deep-purple--text' }"
						@click.prevent="viewDetails(item.id)"
					>
						<div v-if="statusIcon">
							<v-list-item-avatar
								:color="color(item.trackingStatus)"
								class="mr-2"
								size="35"
								tile
							>
								<v-icon dark> {{ icon(item.status) }} </v-icon>
							</v-list-item-avatar>
						</div>
						<div v-else>
							<v-list-item-avatar
								:color="'#6200ea'"
								class="mr-2"
								size="35"
							>
								<v-icon
									dark
									v-if="item.trip === 'A2H'"
									v-text="'mdi-airplane-landing'"
								></v-icon>
								<v-icon
									dark
									v-else-if="item.trip === 'H2A'"
									v-text="'mdi-airplane-takeoff'"
								></v-icon>
								<v-icon
									dark
									v-else
									v-text="'mdi-map-marker'"
								></v-icon>
							</v-list-item-avatar>
						</div>
						
						<v-list-item-content>
							<v-list-item-title
								v-html="item.pickup.name"
							></v-list-item-title>

							<v-list-item-subtitle
								v-html="item.dropoff.name"
							></v-list-item-subtitle>
							<v-progress-linear
								v-if="
									item.status == 'active' &&
										item.trackingStatus !== 'pending' &&
										item.trackingStatus !== 'finished' &&
										item.trackingStatus !== 'noShow'
								"
								buffer-value="0"
								color="success"
								reverse
								stream
								value="0"
							></v-progress-linear>
						</v-list-item-content>

						<v-list-item-action class="ml-1 mt-2 mb-1">
							<v-list-item-action-text class="text-right">
								<strong>
									<template
										v-if="
											$moment(item.pickupDatetime).format(
												'YYYY-MM-DD'
											) !== dateCalendar
										"
										>{{
											item.pickupDatetime
												| moment("D MMM YYYY")
										}}<br
									/></template>
									{{ item.pickupDatetime | moment("HH:mm") }}
									hrs</strong
								>
							</v-list-item-action-text>

							<v-list-item-action-text>
								ID: <strong>{{ item.serviceNumber }}</strong>
							</v-list-item-action-text>

							<v-list-item-action-text>
								<strong>{{ item.reservation.adults }}</strong>
								PAX
							</v-list-item-action-text>
							<v-list-item-action-text class="text-right">
								<v-chip color="info" text-color="white" small dense>
									{{ $t("lblTrackingStatus."+ item.trackingStatus+"") }}
									</v-chip>
							</v-list-item-action-text>
						</v-list-item-action>
					</v-list-item>
					<v-divider :key="index"></v-divider>
				</template>
			</v-list>
			<Empty
				v-else
				icon="checkbox-marked-outline"
				:msg="$t('global.components.listServices.txtEmptyServices')"
			/>
		</div>
	</div>
</template>

<script>
/**
 *  Icons:
 *  canceled => close-thick => close: red darken-3
 *  noShow => eye-off-outline: pink darken-3
 *  done => check => check-outline: green darken-3
 *  noProvided => help: yellow darken-3
 *  pending => pause: teal darken-3
 *
 */

const Loading = () =>
	import(/* webpackChunkName: "Loading" */ "@/components/loading.vue");
const Empty = () =>
	import(/* webpackChunkName: "Empty" */ "@/components/empty.vue");

import { mapActions, mapState, mapMutations } from "vuex";

export default {
	name: "ListServices",
	components: { Loading, Empty },
	props: {
		status: {
			type: Array,
			required: true,
		},
		textCalendar: {
			type: String,
			required: true,
		},
		limitCalendar: {
			type: Number,
			defualt() {
				return 30;
			},
		},
		reverse: {
			type: Boolean,
			default() {
				return false;
			},
		},
		statusIcon: {
			type: Boolean,
			default() {
				return false;
			},
		},
		dateCalendarProp: {
			type: String,
			default() {
				return new Date().toISOString().substr(0, 10);
			},
		},
		value: String,
	},
	data() {
		return {
			date: this.$moment().format("YYYY-MM-DD"),
			active: null,
			isLoading: true,
			items: [],
			itemsBackup: [],
			modal: false,
			dateCalendar: null,
			isSearch: false,
			idSearch: null,
			applySearch: false,
			timeId: null,
			erroMsg:null,
			errorFlag:false

		};
	},
	computed: {
		...mapState("services", { service: (state) => state.data }),
		...mapState({ lang: (state) => state.lang }),
	},
	methods: {
		...mapActions("ui", {
			showProgress: "showProgress",
			hideProgress: "hideProgress",
		}),
		...mapMutations("services", {
			setService: "setService"
		}),
		...mapMutations("ui", {
			openSnackbar: "openSnackbar",
		}),
		viewDetails(id) {
			// Dar un poco de tiempo para mirar efecto
			setTimeout(() => {
				this.$router.push({ name: "booking", params: { id } });
			}, 250);
		},
		refreshServices() {
			this.modal = false;
			this.$emit("input", this.dateCalendar);
			this.$refs.dialog.save(this.dateCalendar);
			this.getServices(this.dateCalendar, false);
		},
		realodServices() {
			this.showProgress();
			this.getServices(this.dateCalendar, false);
		},
		searchActive() {
			this.isSearch = true;
		},
		backCommand() {
			this.isSearch = false;
			this.idSearch = null;
			if (this.applySearch) {
				this.clearSearch();
			}
		},
		search() {
			if (this.itemsBackup.length < 1) {
				this.itemsBackup = this.items;
			}
			this.items = [];
			this.applySearch = true;
			// Crear la expresion
			const reg = new RegExp(`${parseInt(this.idSearch)}.*`);
			// buscar cualquier coincidencia
			this.itemsBackup.forEach((service) => {
				if (reg.test(service.reservationId)) {
					this.items.push(service);
				}
			});
		},
		clearSearch() {
			this.items = this.itemsBackup;
			this.itemsBackup = [];
			this.applySearch = false;
			this.timeId = null;
		},
		getServices(date, first) {
			if (first === undefined) {
				first = true;
			}

			if (!first) {
				this.showProgress();
			}
			this.$axios
				.post(`driver/assigned-transport-services/${date}`, {
					status: this.status,
				})
				.then((res) => {
					if (this.reverse) {
						this.items = res.data.reverse();
					} else {
						if (this.service === null) {
							// this.findActive(res.data);
						}
						this.items = res.data;
					}
				})
				.catch(()=>{
					this.openSnackbar(this.$t("forgot.txTunexpectedError"));
				})
				.finally(() => {
					if (first) {
						this.isLoading = false;
					} else {
						setTimeout(() => {
							this.hideProgress();
						}, 500);
					}
				});
		},
		findActive(data) {
			if (data.length > 0) {
				data.forEach((item) => {
					if (
						item.status === "active" &&
						item.trackingStatus !== "pending" &&
						item.trackingStatus !== "noShow"
					) {
						this.setService(item);
					}
				});
			}
		},
		getDate(days) {
			const d = days * 86400000;
			const ms = new Date().getTime() + d;
			return new Date(ms);
		},
		getFormatDate(days) {
			return this.getDate(days)
				.toISOString()
				.substr(0, 10);
		},
		color(trackingStatus) {
			if (trackingStatus === "canceled") {
				return "red darken-3";
			} else if (trackingStatus === "noShow") {
				return "pink darken-3";
			} else if (trackingStatus === "done") {
				return "green darken-3";
			} else if (trackingStatus === "noProvided") {
				return "yellow darken-3";
			} else if (trackingStatus === "pending") {
				return "teal darken-3";
			} else {
				return "blue-grey darken-3";
			}
		},
		icon(trackingStatus) {
			if (trackingStatus === "canceled") {
				return "mdi-close-thick";
			} else if (trackingStatus === "noShow") {
				return "mdi-eye-off-outline";
			} else if (trackingStatus === "done") {
				return "mdi-check";
			} else if (trackingStatus === "noProvided") {
				return "mdi-help";
			} else if (trackingStatus === "pending") {
				return "mdi-pause";
			} else {
				return "mdi-map-marker";
			}
		},
	},
	watch: {
		idSearch(newValue) {
			console.log("=>", newValue);
			// Clear al time
			if (this.timeId !== null) {
				clearTimeout(this.timeId);
			}

			// Ver si no se quedo vacio
			if (newValue.length < 1) {
				this.clearSearch();
			} else {
				this.timeId = setTimeout(() => {
					this.search();
				}, 500);
			}
		},
	},
	mounted() {
		if (this.value === null) {
			this.dateCalendar = new Date().toISOString().substr(0, 10);
			this.getServices(this.date, true);
		} else {
			this.dateCalendar = this.value;
			this.getServices(this.dateCalendar, true);
		}
	},
};
</script>

<style scoped>
.v-text-field__details {
	display: none !important;
}
.iconWhite {
	color: #fff !important;
}
</style>
